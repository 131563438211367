import React, { useContext } from 'react'
import AppContext from '../contexts/AppContext'
import bbCode from 'bbcode-to-react'
import Logo from '../images/logo.png'
import { FooterLogo, FooterNotice, FooterNoticeWarning, FooterContainer, FooterLine } from './Styled'

const Footer = () => {
    const { bindResizeEvent, config } = useContext(AppContext)
    const line = {
        letterSpacing: 0.07,
        lineHeight: 1
    }

    return <>
        <FooterLogo src={Logo} />
        {
            config.footer && config.footer.notice ?
                <FooterNotice ref={bindResizeEvent('footer.notice', {
                    fontSize: 0.92,
                    lineHeight: 0
                })}>
                    {
                        Array.isArray(config.footer.notice) ?
                            config.footer.notice.map((text, i) =>
                                <React.Fragment key={i}>
                                    {bbCode.toReact(text)}<br />
                                </React.Fragment>
                            ) :
                            bbCode.toReact(config.footer.notice)
                    }
                </FooterNotice> : null
        }
        {
            config.footer && config.footer.noticeWarning ?
                <FooterNoticeWarning ref={bindResizeEvent('footer.noticeWarning', {
                    fontSize: 0.92,
                    lineHeight: 1.0,
                })}>
                    {
                        Array.isArray(config.footer.noticeWarning) ?
                            config.footer.noticeWarning.map((text, i) =>
                                <React.Fragment key={i}>
                                    {bbCode.toReact(text)}<br />
                                </React.Fragment>
                            ) :
                            bbCode.toReact(config.footer.noticeWarning)
                    }
                </FooterNoticeWarning> : null
        }
        {
            config.footer && config.footer.info ?
                <FooterContainer ref={bindResizeEvent('footer.container', {
                    right: 0.5,
                    fontSize: 0.4,
                })}>
                    {
                        (config.footer.info || []).map((text, i) =>
                            <FooterLine key={i} ref={bindResizeEvent(`footer.line.${i + 1}`, line)}>
                                {
                                    text && Array.isArray(text) ? text.map((text, i) =>
                                        <React.Fragment key={i}>
                                            {bbCode.toReact(text)}<br />
                                        </React.Fragment>) :
                                        bbCode.toReact(text)
                                }
                            </FooterLine>
                        )
                    }
                </FooterContainer> : null
        }
    </>
}

export default Footer