import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../contexts/AppContext'
import Menu from './Menu'
import Diagram from './Diagram'
import { BodyContainer } from './Styled'

const Body = props => {
    const { trackCombination } = useContext(AppContext)
    const [leftItem, setLeftItem] = useState(null)
    const [rightItem, setRightItem] = useState(null)

    const onClick = props => {
        const { location, item } = props

        switch (location) {
            case 'left':
                setLeftItem(item)
                break
            case 'right':
                setRightItem(item)
                break
            default:
        }
    }

    useEffect(() => {
        if (leftItem !== null && rightItem !== null) {
            trackCombination(leftItem, rightItem)
        }
    }, [leftItem, rightItem, trackCombination])

    return <BodyContainer>
        <Menu location="left" items={props.menu} onClick={onClick} />
        <Diagram left={leftItem} right={rightItem} />
        <Menu location="right" items={props.menu} onClick={onClick} />
    </BodyContainer>
}

export default Body