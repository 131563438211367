import React from 'react'
import ReactDOM from 'react-dom'
import StringMath from 'string-math'
import AspectRatio from '@dbpx/aspect-ratio'
import App from './components/App'
import { GlobalStyles, Background } from './components/Styled'
import AppContext from './contexts/AppContext'
import './fonts.css'
import './global.css'

ReactDOM.render(
    <React.Fragment>
        <GlobalStyles />
        <React.StrictMode>
            <AppContext.Provider>
                <Background className="noselect">
                    <AppContext.Consumer>
                        {
                            ({ config }) => config && config.ratio ?
                                <AspectRatio ratio={
                                    typeof config.ratio === 'string' ?
                                        StringMath(config.ratio) :
                                        config.ratio
                                }>
                                    <App />
                                </AspectRatio> :
                                null
                        }
                    </AppContext.Consumer>
                </Background>
            </AppContext.Provider>
        </React.StrictMode>
    </React.Fragment>,
    document.getElementById('root')
)
