import React, { useContext, useRef } from 'react'
import AppContext from '../contexts/AppContext'
import AspectRatio from '@dbpx/aspect-ratio'
import bbCode from 'bbcode-to-react'
import Item from './Item'
import TL from '../images/arrows/tl.png'
import TR from '../images/arrows/tr.png'
import BL from '../images/arrows/bl.png'
import BR from '../images/arrows/br.png'
import {
    DiagramArrow,
    DiagramArrowWrapper,
    DiagramBox,
    DiagramContainer,
    DiagramInfo,
    DiagramText,
    DiagramSubtitle,
    DiagramDefectContainer,
    DiagramDefect,
    DiagramOutcomeWrapper,
    DiagramLabel,
    DiagramWrapper,
    DiagramHolder,
    RareText,
    RareTextBox,
    BodyTitle
} from './Styled'

const Diagram = props => {
    const header = useRef()
    const { config, getOutcome, bindResizeEvent } = useContext(AppContext)
    const sizes = {
        label: {
            fontSize: 1.3
        },
        text: {
            fontSize: 1.4
        },
        subtitle: {
            fontSize: 1
        },
        defect: {
            fontSize: 1
        },
        box: {
            borderWidth: 0.2,
            fontSize: 1.05,
            paddingTop: 0.79,
            paddingBottom: 0.79,
            paddingLeft: 1,
            paddingRight: 1,
            borderRadius: 0.8,
            minHeight: 2.7
        }
    }

    const { color, text, heading } = getOutcome(props.left, props.right);

    if (header && header.current) {
        header.current.innerText = heading
        header.current.style.display = props.left && props.right ? "block" : "none"
    }

    return (
        <DiagramWrapper>
            <AspectRatio ratio={4 / 3}>
                <BodyTitle
                    ref={bindResizeEvent(
                        "body.title",
                        {
                            fontSize: 1.4
                        },
                        header
                    )}
                />
                <DiagramHolder>
                    <DiagramContainer>
                        {props.left ? (
                            <>
                                <Item left="77.5%" color={props.left.color} />
                                <DiagramInfo location="left">
                                    <DiagramLabel
                                        ref={bindResizeEvent("diagram.box.left.label", sizes.label)}
                                    >
                                        {bbCode.toReact(props.left.label)}
                                    </DiagramLabel>
                                    <DiagramText
                                        ref={bindResizeEvent("diagram.box.left.text", sizes.text)}
                                        state={props.left.color}
                                    >
                                        {bbCode.toReact(
                                            config.outcome.title[props.left.color === "P" ? 1 : 0]
                                        )}
                                    </DiagramText>
                                    <DiagramSubtitle
                                        ref={bindResizeEvent(
                                            "diagram.box.left.subtitle",
                                            sizes.subtitle
                                        )}
                                    >
                                        {bbCode.toReact(props.left.subtitle)}
                                    </DiagramSubtitle>
                                </DiagramInfo>
                                <DiagramDefectContainer location="left">
                                    <DiagramDefect
                                        ref={bindResizeEvent(
                                            "diagram.box.left.defect",
                                            sizes.defect
                                        )}
                                    >
                                        {bbCode.toReact(props.left.defect)}
                                    </DiagramDefect>
                                    <DiagramBox
                                        ref={bindResizeEvent("diagram.box.left", sizes.box)}
                                        state={props.left.color}
                                        selected={true}
                                    >
                                        {bbCode.toReact(props.left.title)}
                                    </DiagramBox>
                                </DiagramDefectContainer>
                            </>
                        ) : null}
                    </DiagramContainer>
                    <RareTextBox>
                        <RareText ref={bindResizeEvent("diagram.rare.text", {
                            fontSize: 0.85
                        })}>
                            {
                                props.left && props.right && props.left.color === "O" && props.right.color === "O" ?
                                    (
                                        config.outcome && config.outcome.info ?
                                            (
                                                Array.isArray(config.outcome.info) ?
                                                    config.outcome.info.map((text, i) =>
                                                        <React.Fragment key={i}>
                                                            {bbCode.toReact(text)}<br />
                                                        </React.Fragment>
                                                    ) :
                                                    bbCode.toReact(config.outcome.info)
                                            ) : null
                                    ) : null
                            }
                        </RareText>
                    </RareTextBox>
                    <DiagramContainer>
                        {props.right ? (
                            <>
                                <Item left="7%" color={props.right.color} />
                                <DiagramInfo location="right">
                                    <DiagramLabel
                                        ref={bindResizeEvent(
                                            "diagram.box.right.label",
                                            sizes.label
                                        )}
                                    >
                                        {bbCode.toReact(props.right.label)}
                                    </DiagramLabel>
                                    <DiagramText
                                        ref={bindResizeEvent("diagram.box.right.text", sizes.text)}
                                        state={props.right.color}
                                    >
                                        {bbCode.toReact(
                                            config.outcome.title[props.right.color === "P" ? 1 : 0]
                                        )}
                                    </DiagramText>
                                    <DiagramSubtitle
                                        ref={bindResizeEvent(
                                            "diagram.box.right.subtitle",
                                            sizes.subtitle
                                        )}
                                    >
                                        {bbCode.toReact(props.right.subtitle)}
                                    </DiagramSubtitle>
                                </DiagramInfo>
                                <DiagramDefectContainer location="right">
                                    <DiagramDefect
                                        ref={bindResizeEvent(
                                            "diagram.box.right.defect",
                                            sizes.defect
                                        )}
                                    >
                                        {bbCode.toReact(props.right.defect)}
                                    </DiagramDefect>
                                    <DiagramBox
                                        ref={bindResizeEvent("diagram.box.right", sizes.box)}
                                        state={props.right.color}
                                        selected={true}
                                    >
                                        {bbCode.toReact(props.right.title)}
                                    </DiagramBox>
                                </DiagramDefectContainer>
                            </>
                        ) : null}
                    </DiagramContainer>
                    <DiagramOutcomeWrapper>
                        {props.left && props.right ? (
                            <DiagramBox
                                ref={bindResizeEvent("diagram.box.output", sizes.box)}
                                state={color}
                                selected={true}
                            >
                                {bbCode.toReact(text)}
                            </DiagramBox>
                        ) : null}
                    </DiagramOutcomeWrapper>
                    <DiagramArrowWrapper>
                        <DiagramArrow src={TL} active={props.left} />
                        <DiagramArrow src={TR} active={props.right} />
                        <DiagramArrow src={BL} active={props.left && props.right} />
                        <DiagramArrow src={BR} active={props.left && props.right} />
                    </DiagramArrowWrapper>
                </DiagramHolder>
            </AspectRatio>
        </DiagramWrapper>
    )
}

export default Diagram
