import React, { forwardRef, useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { Wrapper } from './Styled'

const Layout = forwardRef((props, ref) => {
    const [active, setActive] = useState(false)

    useEffect(() => {
        if (ref.current) {
            setActive(true)
        }
    }, [ref])
    
    return <Wrapper ref={ref}>
        {
            active ? <>
                <Header />
                {props.children}
                <Footer />
            </> : null
        }
    </Wrapper>
})

export default Layout