import React, { useContext } from 'react'
import AppContext from '../contexts/AppContext'
import Layout from './Layout'
import Body from './Body'

const App = () => {
    const { config, viewport } = useContext(AppContext)

    return <>
        {
            config ? <Layout ref={viewport}>
                <Body menu={config.menu} />
            </Layout> : null
        }
    </>
}

export default App
