import React from 'react'
import Image from '../images/item.png'
import { ItemContainer, ItemImage, ItemColor } from './Styled'

const Item = props => {
    return <ItemContainer left={props.left}>
        <ItemImage src={Image} />
        <ItemColor state={props.color} />
    </ItemContainer>
}

export default Item