import React, { useContext, useState } from 'react'
import AppContext from '../contexts/AppContext'
import bbCode from 'bbcode-to-react'
import { MenuContainer, MenuBox, MenuWrapper } from './Styled'

const Menu = props => {
    const { bindResizeEvent } = useContext(AppContext)
    const [itemSelected, setSelectedItem] = useState(null)

    const onClick = item => () => {
        setSelectedItem(item.id)

        if (props.onClick && props.onClick instanceof Function) {
            props.onClick({
                location: props.location,
                item
            })
        }
    }

    return <MenuWrapper location={props.location}>
        <MenuContainer location={props.location} ref={bindResizeEvent(`menu.${props.location}.gap`, {
            gridRowGap: 0.62,
            gridColumnGap: 0.9
        })}>
            {
                props.items.map(item => <MenuBox
                    key={item.id}
                    state={item.color}
                    selected={item.id === itemSelected}
                    onClick={onClick(item)}
                    ref={bindResizeEvent(`menu.box.${props.location}.${item.id}`, {
                        fontSize: 0.9,
                        borderWidth: 0.2,
                        paddingTop: 0.3,
                        paddingBottom: 0.3,
                        paddingLeft: 0.1,
                        paddingRight: 0.1,
                        borderRadius: 0.4,
                    })}
                >{bbCode.toReact(item.label)}</MenuBox>)
            }
        </MenuContainer>
    </MenuWrapper>
}

export default Menu