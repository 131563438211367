import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

const _AppContext = createContext()

const Provider = _AppContext.Provider

const uuid = uuidv4()
const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiVmVydGV4In0.cRxT1me7JO01hnxLRVG_QG31yFo8Qx2EOLNP_q4Vz2U'

let elements = {}
let delayTimeout

const AppContext = props => {
    const [config, setConfig] = useState()
    const [queue, setQueue] = useState({})
    const viewport = useRef()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resizeCallback = useCallback((_, el) => resize(el), [])

    const vh = v => (v * viewport.current.clientHeight) / 100
    const vw = v => (v * viewport.current.clientWidth) / 100
    const vmin = v => Math.min(vh(v), vw(v))
    const vmax = v => Math.max(vh(v), vw(v))

    const resize = (el, delay = true) => {
        if (viewport.current) {
            if (el) {
                Object.keys(el.style || {}).forEach(prop => {
                    el.element.style[prop] = `${vw(el.style[prop])}px`
                })
            } else {
                Object.keys(elements).forEach(key => resize({
                    key,
                    ...elements[key]
                }))
            }
            if (delay) {
                clearTimeout(delayTimeout)
                delayTimeout = setTimeout(() => resize(el, false), 100)
            }
        }
    }

    const trackCombination = (left, right) => {
        axios.post('/api/track/combination', {
            show: config.show || null,
            uuid,
            left,
            right,
            outcome: getOutcome(left, right),
            timestamp: moment.utc().format('YYYY-MM-DD HH:mm:ss')
        }, {
            headers: { Authorization: `Bearer ${token}` }
        })
    }

    const bindResizeEvent = (key, style, ref) => element => {
        if (
            viewport.current &&
            element !== null &&
            !elements.hasOwnProperty(key) &&
            !queue.hasOwnProperty(key)
        ) {
            elements = { ...elements, [key]: { element, style } }

            resizeCallback(null, { key, element, style })
        } else if (
            !viewport.current &&
            element !== null &&
            !queue.hasOwnProperty(key) &&
            !elements.hasOwnProperty(key)
        ) {
            setQueue({ ...queue, [key]: { element, style } })
        }

        if (ref) {
            ref.current = element
        }
    }

    const getOutcome = (left, right) => {
        const c1 = left ? left.color.toUpperCase() : ''
        const c2 = right ? right.color.toUpperCase() : ''

        let color = (config.outcome && config.outcome.color) || ''
        let text = (config.outcome && config.outcome.text) || ''
        let heading = (config.outcome && config.outcome.heading) || ''
        let rules = (config.outcome && config.outcome.rules) || []

        if (config.outcome) {
            if (rules.length) {
                for (let i = 0; i < rules.length; i += 1) {
                    const rule = rules[i]

                    if (rule.hasOwnProperty('test')) {
                        const strict = typeof rule.test === 'string'

                        if (
                            (strict && (c1 === rule.test && c2 === rule.test)) ||
                            (!strict && (c1 === rule.test[0] || c2 === rule.test[1]))
                        ) {
                            if (rule.hasOwnProperty('heading')) {
                                heading = rule.heading
                            }
                            if (rule.hasOwnProperty('text')) {
                                text = rule.text
                            }
                            if (rule.hasOwnProperty('color')) {
                                color = rule.color
                            }
                            break
                        }
                    }
                }
            }
        }

        return {
            color,
            text,
            heading
        }
    }
    
    useEffect(() => {
        if (viewport.current && Object.keys(queue).length) {
            Object.keys(queue).forEach(key => {
                const { element, style } = queue[key]

                elements = { ...elements, [key]: { element, style } }

                resizeCallback(null, { key, element, style })
            })

            setQueue({})
        }
    }, [viewport, resizeCallback, queue])

    useEffect(() => {
        axios('/config.json')
            .then(res => res.data)
            .then(data => {
                if (!data.hasOwnProperty('ratio')) {
                    const gcd = (a, b) => b === 0 ? a : gcd(b, a % b)
                    const w = document.body.clientWidth
                    const h = document.body.clientHeight
                    const r = gcd(w, h)

                    data.ratio = (
                        data.hasOwnProperty('fitInitialWindow') &&
                        data.fitInitialWindow === true
                    ) ? (w / r) / (h / r) : 16 / 9
                }

                setConfig(data)
            })
            .catch(console.error)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', resizeCallback)

        resizeCallback()

        return () => {
            window.removeEventListener('resize', resizeCallback)
        }
    }, [resizeCallback])

    return <Provider value={{
        config,
        bindResizeEvent,
        getOutcome,
        trackCombination,
        vh,
        viewport,
        vmin,
        vmax,
        vw,
    }}>
        {props.children}
    </Provider>
}

_AppContext.displayName = 'AppContext'
_AppContext.Provider = AppContext

export default _AppContext