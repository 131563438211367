import styled, { createGlobalStyle } from 'styled-components'
import BackgroundImage from '../images/background.png'

export const Colors = {
    'R': '#be0200',
    'P': '#38006a',
    'O': '#e46d08'
}

export const GlobalStyles = createGlobalStyle(() => ({
    "html, body, #root": {
        margin: 0,
        width: '100%',
        height: '100%',
        fontFamily: "Avenir, 'sans-serif'"
    }
}))

export const Background = styled.div(() => ({
    width: '100%',
    height: '100%',
    backgroundImage: `url(${BackgroundImage})`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover'
}))

export const Wrapper = styled.div(() => ({
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundSize: '100%'
}))

export const HeaderContainer = styled.div(() => ({
    textAlign: 'center'
}))

export const HeaderTitle = styled.h1(props => ({
    margin: '0',
    color: '#38006a'
}))

export const HeaderSubtitle = styled.h2(() => ({
    margin: '0',
    color: '#38006a'
}))

export const BodyContainer = styled.div(() => ({
    height: '100%',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    top: '-2%'
}))

export const BodyTitle = styled.div(() => ({
    width: '100%',
    pointerEvents: 'none',
    position: 'absolute',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'none'
}))

export const MenuContainer = styled.div(props => ({
    position: 'relative',
    display: 'grid',
    top: 0,
    zIndex: 2,
    gridTemplateColumns: 'repeat(2, 1fr)',
    left: props.location !== 'right' ? '2.6%' : null,
    right: props.location === 'right' ? '2.6%' : null,
}))

export const RareTextBox = styled.div(() => ({
    position: 'absolute',
    top: '67%',
    width: '100%',
    textAlign: 'center',
    left: 0,
}))

export const RareText = styled.div(() => ({
    color: 'black',
}))

export const MenuBox = styled.div(props => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: Colors[String(props.state).toUpperCase()] || 'transparent',
    backgroundColor: props.selected ? Colors[String(props.state).toUpperCase()] : '#fefefe',
    color: props.selected ? '#ffffff' : '#828186',
    fontStyle: 'italic',
    cursor: 'pointer',
}))

export const MenuWrapper = styled.div(props => ({
    flex: 1,
    padding: props.location === 'left' ? '0 0 5% 3%' : '0 3% 5% 0'
}))

export const DiagramBox = styled.div(props => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: Colors[String(props.state).toUpperCase()] || 'transparent',
    backgroundColor: props.selected ? Colors[String(props.state).toUpperCase()] : '#fefefe',
    color: props.selected ? '#ffffff' : '#828186',
    fontWeight: 'bold',
    margin: '3% 2.5%',
}))

export const DiagramContainer = styled.div(() => ({
    display: 'inline-flex',
    width: '50%',
    height: '74%',
    position: 'relative'
}))

export const DiagramWrapper = styled.div(() => ({
    display: 'inline-flex',
    width: '100%',
    height: '100%',
    position: 'relative',
    flex: 2.5,
    padding: '0 5%',
    justifyContent: 'center',
    alignItems: 'center',
    top: '5%'
}))

export const DiagramHolder = styled.div(() => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    top: '5%',
    left: 0
}))

export const DiagramInfo = styled.div(props => ({
    width: '69%',
    marginTop: '15%',
    marginLeft: props.location === 'left' ? '4%' : '28%',
    height: '34%',
    textAlign: props.location === 'left' ? 'right' : 'left'
}))

export const DiagramLabel = styled.div(() => ({
    color: '#1d75bc',
    fontStyle: 'italic',
    fontWeight: 'bold',
    marginTop: '3%',
    marginRight: '3%'
}))

export const DiagramText = styled.div(props => ({
    color: Colors[String(props.state).toUpperCase()] || Colors.P,
    fontWeight: 'bold',
    marginRight: '3.3%',
    marginTop: '2%'
}))

export const DiagramSubtitle = styled.div(() => ({
    marginTop: '2%',
    marginRight: '3%',
    color: '#565656'
}))

export const DiagramOutcomeWrapper = styled.div(() => ({
    position: 'absolute',
    width: '45.5%',
    marginLeft: '27%',
    marginTop: '56.5%',
    textAlign: 'center'
}))

export const DiagramArrowWrapper = styled.div(() => ({
    position: 'absolute',
    pointerEvents: 'none',
    width: '60.2%',
    marginLeft: '20%',
    marginTop: '25%'
}))

export const DiagramArrow = styled.img(props => ({
    position: 'absolute',
    pointerEvents: 'none',
    top: 0,
    left: 0,
    width: '100%',
    display: props.active ? 'block' : 'none',
    userSelect: 'none'
}))

export const DiagramDefectContainer = styled.div(props => ({
    position: 'absolute',
    top: '59%',
    left: props.location === 'right' ? '22%' : '4%',
    width: '72.6%',
    textAlign: 'center'
}))

export const DiagramDefect = styled.div(() => ({
    fontWeight: 'bold',
    margin: '2% 3%'
}))

export const ItemContainer = styled.div(props => ({
    position: 'absolute',
    pointerEvents: 'none',
    left: props.left,
    top: '6.9%',
    width: '12%'
}))

export const ItemImage = styled.img(() => ({
    width: '100%',
    pointerEvents: 'none',
    position: 'relative',
    zIndex: 2,
    userSelect: 'none'
}))

export const ItemColor = styled.div(props => ({
    width: '100%',
    height: '20%',
    pointerEvents: 'none',
    backgroundColor: Colors[String(props.state).toUpperCase()] || '#fefefe',
    position: 'absolute',
    top: '60%',
    zIndex: 1
}))

export const FooterLogo = styled.img(() => ({
    width: '8%',
    height: 'auto',
    bottom: '1%',
    left: '1%',
    position: 'absolute',
    pointerEvents: 'none',
    userSelect: 'none'
}))

export const FooterNotice = styled.div(() => ({
    textAlign: 'center',
    fontWeight: 500,
    position: 'relative',
    bottom: '1.5%'
}))

export const FooterNoticeWarning = styled.div(() => ({
    textAlign: 'center',
    color: '#323232',
    fontWeight: 600
}))

export const FooterContainer = styled.div(() => ({
    position: 'absolute',
    bottom: 0,
    textAlign: 'right',
}))

export const FooterLine = styled.h2(() => ({
    color: '#595959',
}))