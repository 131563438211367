import React, { useContext } from 'react'
import AppContext from '../contexts/AppContext'
import bbCode from 'bbcode-to-react'
import { HeaderContainer, HeaderTitle, HeaderSubtitle } from './Styled'

const Header = () => {
    const { bindResizeEvent, config } = useContext(AppContext)

    return <HeaderContainer>
        <HeaderTitle ref={bindResizeEvent('header.title', {
            letterSpacing: 0.07,
            marginTop: 1.3,
            lineHeight: 4.8,
            fontSize: 4.7,
        })}>
            {
                config.header && config.header.title ?
                    Array.isArray(config.header.title) ?
                        config.header.title.map((text, i) =>
                            <React.Fragment key={i}>
                                {bbCode.toReact(text)}<br />
                            </React.Fragment>
                        ) :
                        bbCode.toReact(config.header.title)
                    : null
            }
        </HeaderTitle>
        <HeaderSubtitle ref={bindResizeEvent('header.subtitle', {
            letterSpacing: 0.07,
            marginTop: 1.7,
            lineHeight: 1.8,
            fontSize: 1.4,
        })}>
            {
                config.header && config.header.subtitle ?
                    Array.isArray(config.header.subtitle) ?
                        config.header.subtitle.map((text, i) =>
                            <React.Fragment key={i}>
                                {bbCode.toReact(text)}<br />
                            </React.Fragment>
                        ) :
                        bbCode.toReact(config.header.subtitle)
                    : null
            }
        </HeaderSubtitle>
    </HeaderContainer>
}

export default Header